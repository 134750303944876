window.mode = "kraftorte";

import {loadMap} from './map.js';

import markerClusterGroup from 'leaflet.markercluster';
import layerSupport from 'leaflet.markercluster.layersupport';
import geocoder from 'leaflet-control-geocoder';
import locate from 'leaflet.locatecontrol';
import Fullscreen from 'leaflet-fullscreen';
import Routing from 'leaflet-routing-machine';

import 'leaflet-defaulticon-compatibility';
import 'leaflet-extra-markers';
import 'leaflet.control.layers.tree';
import 'leaflet-sidebar-v2';
import 'leaflet-arc';

import 'leaflet.heat/dist/leaflet-heat.js';

import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet.locatecontrol/dist/L.Control.Locate.css';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';
import 'leaflet.control.layers.tree/L.Control.Layers.Tree.css';
import 'leaflet-sidebar-v2/css/leaflet-sidebar.css';
import './css/style.css';

const queryString = window.location.search;
window.urlParams = new URLSearchParams(queryString);

var map = L.map('map', {
  preferCanvas: false,
  fullscreenControl: {
    pseudoFullscreen: false, // if true, fullscreen to page width and height
    position: 'topright'
  },
  attributionControl: true,
  zoomControl: false,
  scrollWheelZoom: true,
  tap: false
}).setView([41.76,-72.69], 11);

var blanche_merz = L.layerGroup();
// Externsteine
L.Polyline.Arc([47.32745038, 8.660799265], [51.868889, 8.9175],  {
    color: 'orange',
    vertices: 200
}).bindPopup('<h3>Aesch bei Forch - Externsteine</h3><p>Azimut: 2.0067883°</p>').addTo(blanche_merz);
// Verlängerung Externsteine
L.Polyline.Arc([47.32745038, 8.660799265], [29.31866717711826, 7.95156786208286], {
    color: 'yellow',
    vertices: 200
}).bindPopup('<h3>Verlängerung Externsteine</h3><p>Azimut: 182.0067883°</p>').addTo(blanche_merz);
// Giza
L.Polyline.Arc([47.32745038, 8.660799265], [29.979167, 31.134167], {
    color: 'orange',
    vertices: 200
}).bindPopup('<h3>Aesch bei Forch - Giza</h3><p>Azimut: 126.9182267°</p>').addTo(blanche_merz);
// Verlängerung Giza
L.Polyline.Arc([47.32745038, 8.660799265], [52.17150478375201, -3.0589837804413156], {
    color: 'yellow',
    vertices: 200
}).bindPopup('<h3>Verlängerung Giza</h3><p>Azimut: 306.9182267°</p>').addTo(blanche_merz);
// Azoren
L.Polyline.Arc([47.32745038, 8.660799265], [37.771389, -25.461944], {
    color: 'orange',
    vertices: 200
}).bindPopup('<h3>Aesch bei Forch - Azoren</h3><p>Azimut: 261.6086351°</p>').addTo(blanche_merz);
// Verlängerung Azoren
L.Polyline.Arc([47.32745038, 8.660799265], [47.87561010089116, 21.95078591134892], {
    color: 'yellow',
    vertices: 200
}).bindPopup('<h3>Verlängerung Azoren</h3><p>Azimut: 81.6086351°</p>').addTo(blanche_merz);
// Ermitage bei Arlesheim
L.Polyline.Arc([47.32745038, 8.660799265], [47.49151849, 7.62832582], {
    color: 'red',
    vertices: 200
}).bindPopup('<h3>Ermitage bei Arlesheim</h3>').addTo(blanche_merz);
// Eschentzwiller
L.Polyline.Arc([47.32745038, 8.660799265], [47.71248675, 7.399077415], {
    color: 'red',
    vertices: 200
}).bindPopup('<h3>Eschentzwiller</h3>').addTo(blanche_merz);
// Bludesch/Ludesch
L.Polyline.Arc([47.32745038, 8.660799265], [47.199611, 9.747139], {
    color: 'red',
    vertices: 200
}).bindPopup('<h3>Bludesch/Ludesch</h3>').addTo(blanche_merz);
// Prag / Breslau / Warschau
L.Polyline.Arc([47.32745038, 8.660799265], [52.216667, 21.033333], {
    color: 'red',
    vertices: 200
}).bindPopup('<h3>Prag / Breslau / Warschau</h3>').addTo(blanche_merz);
// Grossmünster
//L.Polyline.Arc([47.32745038, 8.660799265], [47.74786233666971, 7.486596963566643], {
//    color: 'black',
//    vertices: 200
//}).bindPopup('Grossmünster').addTo(blanche_merz);
//  Ermitage bei Arlesheim / Eschentzwiller
L.Polyline.Arc([47.49151849, 7.62832582], [47.71248675, 7.399077415], {
    color: 'red',
    vertices: 200
}).bindPopup('<h3>Ermitage bei Arlesheim / Eschentzwiller</h3>').addTo(blanche_merz);
// Giza - Externsteine
L.Polyline.Arc([29.979167, 31.134167], [51.868889, 8.9175], {
    color: 'yellow',
    vertices: 200
}).bindPopup('<h3>Giza - Externsteine</h3>').addTo(blanche_merz);
// Azoren - Externsteine
L.Polyline.Arc([37.771389, -25.461944], [51.868889, 8.9175], {
    color: 'yellow',
    vertices: 200
}).bindPopup('<h3>Azoren - Externsteine</h3>').addTo(blanche_merz);
// Azoren - Giza
L.Polyline.Arc([37.771389, -25.461944], [29.979167, 31.13416], {
    color: 'yellow',
    vertices: 200
}).bindPopup('<h3>Azoren - Giza</h3>').addTo(blanche_merz);

var achtvierzig = L.layerGroup();
// 8° 40'
L.Polyline.Arc([60, 8.666667], [30, 8.666667], {
    color: 'white',
    vertices: 200
}).bindPopup('<h3>8° 40\'</h3><p>Die von Blanche Merz postulierte 8° 30"-Linie liegt gemäss meinen Berechnungen bei 8° 40\'.</p>').addTo(achtvierzig);

var moeller = L.layerGroup();
// Externsteine - Cagliari
L.Polyline.Arc([51.868889, 8.9175], [39.216667, 9.116667], {
    color: 'green',
    vertices: 400
}).bindPopup('Meridian-Linie (Extern-Steine - Cagliari)').addTo(moeller);
// Helmstedt - Aix-en-Provence
L.Polyline.Arc([52.228056, 11.010556], [43.527778, 5.445556], {
    color: 'green',
    vertices: 400
}).bindPopup('Deutschland-Linie (Helmstedt - Aix-en-Provence)').addTo(moeller);
// Basel - Regenstauf
L.Polyline.Arc([47.5564619, 7.5924433], [49.122778, 12.127778], {
    color: 'green',
    vertices: 400
}).bindPopup('Basel - Regenstauf)').addTo(moeller);
// Basel - Aachen
L.Polyline.Arc([47.5564619, 7.5924433], [50.775278, 6.083889], {
    color: 'green',
    vertices: 400
}).bindPopup('Basel - Aachen)').addTo(moeller);
// Basel - Enschede
L.Polyline.Arc([47.5564619, 7.5924433], [52.223611, 6.895556], {
    color: 'green',
    vertices: 400
}).bindPopup('Basel - Enschede)').addTo(moeller);

var saint_michael = L.layerGroup();
// Skellig Michael - Monastery of Our Lady of Mount Carmel
L.Polyline.Arc([51.7720358776149, -10.538774728775026], [32.8179, 34.9787], {
    color: 'purple',
    vertices: 400
}).bindPopup('Apollo-St.Michael-Axis (Skellig Michael - Monastery of Our Lady of Mount Carmel)').addTo(saint_michael);

var falera = L.layerGroup();
// Falera - Ladir Kirche
L.Polyline.Arc([46.79977852330626, 9.234982430934908], [46.395335381607765, 8.069242098840698], {
    color: 'white',
    vertices: 400
}).bindPopup('Falera - Ladir Kirche').addTo(falera);
// Falera - Taminser-Calanda
L.Polyline.Arc([46.79977852330626, 9.234982430934908], [47.19214323886165, 10.418059163224976], {
    color: 'white',
    vertices: 400
}).bindPopup('Falera - Taminser-Calanda').addTo(falera);

var gaille = L.layerGroup();
// Schaffhausen - Effretikon
L.Polyline.Arc([47.69526319, 8.636428714], [47.414715924318344, 8.70569944381714], {
    color: 'blue',
    vertices: 400
}).bindPopup('Schaffhausen - Effretikon').addTo(gaille);
// Effretikon - Niederurnen
L.Polyline.Arc([47.414715924318344, 8.70569944381714], [47.11643085878859, 9.078623056411745], {
    color: 'blue',
    vertices: 400
}).bindPopup('Schaffhausen - Niederurnen').addTo(gaille);
// Einsiedeln - St. Gallen
L.Polyline.Arc([47.12675725, 8.752364516], [47.42313874, 9.376688898], {
    color: 'blue',
    vertices: 400
}).bindPopup('Einsiedeln - St. Gallen').addTo(gaille);
// St. Gallen. - St. Margrethen
L.Polyline.Arc([47.42313874, 9.376688898], [47.454065271864444, 9.651886224746706], {
    color: 'blue',
    vertices: 400
}).bindPopup('St. Gallen - St. Margrethen').addTo(gaille);
// Einsiedeln - Schneisingen
L.Polyline.Arc([47.12675725, 8.752364516], [47.53282057913504, 8.364908695220949], {
    color: 'blue',
    vertices: 400
}).bindPopup('Einsiedeln - Schneisingen').addTo(gaille);
// Schneisingen - Vogelsang
L.Polyline.Arc([47.53282057913504, 8.364908695220949], [47.53523994058617, 8.315384387969972], {
    color: 'blue',
    vertices: 400
}).bindPopup('Schneisingen- Vogelsang').addTo(gaille);
// Fischenbach - Rapperswil
L.Polyline.Arc([47.374203722427964, 8.315513134002687], [47.22695653981833, 8.81259083747864], {
    color: 'blue',
    vertices: 400
}).bindPopup('Fischenbach - Rapperswil').addTo(gaille);
// Rapperswil - Sargans
L.Polyline.Arc([47.22695653981833, 8.81259083747864], [47.03372566092122, 9.471727609634401], {
    color: 'blue',
    vertices: 400
}).bindPopup('Rapperswil - Sargans').addTo(gaille);
// Sargans -  Tannkopf
L.Polyline.Arc([47.03372566092122, 9.471727609634401], [47.050358843210326, 9.541819095611574], {
    color: 'blue',
    vertices: 400
}).bindPopup('Sargans - Tannkopf').addTo(gaille);
// Schwyz - Lichtensteig
L.Polyline.Arc([47.01619489092361, 8.622926473617555], [47.32130510792437, 9.085661172866823], {
    color: 'blue',
    vertices: 400
}).bindPopup('Schwyz - Lichtensteig').addTo(gaille);
// Lichtensteig - Horn
L.Polyline.Arc([47.32130510792437, 9.085661172866823], [47.488129257069936, 9.471051692962648], {
    color: 'blue',
    vertices: 400
}).bindPopup('Lichtensteig - Horn').addTo(gaille);
// Horn - 
L.Polyline.Arc([47.488129257069936, 9.471051692962648], [47.7275843613808, 10.033936648331471], {
    color: 'blue',
    vertices: 400
}).bindPopup('Horn - ').addTo(gaille);
// Rapperswil - Unterhalden
L.Polyline.Arc([47.22695653981833, 8.81259083747864], [47.182741784324264, 9.018423557281496], {
    color: 'blue',
    vertices: 400
}).bindPopup('Rapperswil - Unterhalden').addTo(gaille);

//var gaille_roth = L.layerGroup();
// Menhire von Clendy , Kastell Pfäffikon =>
//L.Polyline.Arc([45.600156808197134, 2.796917997486765], [48.78213756641841, 15.159113276663902], {
//    color: 'blue',
//    vertices: 400
//}).bindPopup('Arbon - Menhire von Clendy').addTo(gaille_roth);

var moreLayers = []

var layer = {}
layer['name'] = "Blanche Merz";
layer['data'] = blanche_merz;
layer['category'] = "Kraftlinien";
moreLayers.push(layer);

layer = {}
layer['name'] = "8° 40'";
layer['data'] = achtvierzig;
layer['category'] = "Kraftlinien";
moreLayers.push(layer);

layer = {}
layer['name'] = "Falera Alignment";
layer['data'] = falera;
layer['category'] = "Kraftlinien";
moreLayers.push(layer);

layer = {}
layer['name'] = "Jens M. Möller";
layer['data'] = moeller;
layer['category'] = "Kraftlinien";
moreLayers.push(layer);

//layer = {}
//layer['name'] = "Gaille-Roth";
//layer['data'] = gaille_roth;
//layer['category'] = "Kraftlinien";
//moreLayers.push(layer);

layer = {}
layer['name'] = "Gaille-Linien";
layer['data'] = gaille;
layer['category'] = "Kraftlinien";
moreLayers.push(layer);

layer = {}
layer['name'] = "Apollo - St.Michael - Axis";
layer['data'] = saint_michael;
layer['category'] = "Kraftlinien";
moreLayers.push(layer);

if (!L.Browser.mobile) {
   new L.Control.Zoom({ position: 'topright' }).addTo(map);
}

window.addEventListener("load", loadMap(map, mode, moreLayers));
